export const queryData = {
    data() {
        return {
            queryStatusOptions: {
                RUNNING_QUERY: 'RUNNING',
                FAILED_QUERY: 'FAILED',
                COMPLETED_QUERY: 'COMPLETED',
                TERMINATED_QUERY: 'TERMINATED',
                CREATED_QUERY: 'CREATED',
                COPYING_QUERY: 'COPYING'
            },
            queryModeOptions: {
                PREVIEW_MODE: 'PREVIEW',
                FULL_QUERY_MODE: 'FULL',
                FULL_WITH_SAVE_MODE: 'FULL_WITH_SAVE'
            },
            tableViewTabNames: {
                TABLES: 'tables',
                QUERYBUILDER: 'builder',
                QUERIES: 'queries'
            }
        }
    },
    methods: {
        diffHours: function (dt) {
            const today = new Date()
            const date2 = new Date(dt)
            let diff = (today.getTime() - date2.getTime()) / 1000
            diff /= 60
            return Math.abs(Math.round(diff))
        }
    }
}
